import {
  CreditCardIcon,
  LibraryIcon,
  ShoppingCartIcon,
  TagIcon,
} from "@heroicons/react/outline";
import React from "react";
import { StreamerPage } from "../pages/StreamerPage/StreamerPage";

export const routes = [
  {
    title: "",
    id: "home",
    path: "/",
  },
  {
    title: "",
    id: "streamer",
    path: "/heisenbergofficial",
    Element: <StreamerPage forcedSlug="heisenbergofficial" />,
  },
  {
    title: "",
    id: "streamer",
    path: "/eager",
    Element: <StreamerPage forcedSlug="eager" />,
  },
  {
    title: "",
    id: "streamer",
    path: "/iman",
    Element: <StreamerPage forcedSlug="iman" />,
  },
];
