import { currencyDefinition } from "./AmountInput";
import { classNames } from "../utils/classNames";
import { useEffect } from "react";

export const AmountPreset = ({ preset, currency, value, setValue }) => {
  const p = preset[currency];

  // useEffect(() => {
  //   setValue(p[0]);
  // }, [currency]);

  const currencyShortName = {
    IRT: "ت",
  };

  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
      {p?.map((amount) => {
        const active = amount === value;

        return (
          <div
            className={classNames(
              "p-2px rounded-md cursor-pointer",
              active && "gradient"
            )}
          >
            <div
              className={classNames(
                "border-secondary-light rounded-md price flex justify-center h-12 items-center px-0.5",
                active && "bg-dark-blue border-0",
                !active && "border "
              )}
              onClick={() => setValue(amount)}
            >
              <span className={active ? `opacity-100` : `opacity-70`}>
                {amount?.toLocaleString()}{" "}
                <span className="text-xs">
                  {currencyShortName[currency] ||
                    currencyDefinition[currency] ||
                    currency}
                </span>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
