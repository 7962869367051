import { useContext, useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { CurrencySelect } from "./CurrencySelect";
import { AmountPreset } from "./AmountPreset";
import { FormContext } from "../pages/StreamerPage/DonateForm";

export const currencyDefinition = {
  USD: "دلار آمریکا",
  IRT: "تومان",
};

export const AmountInput = ({
  title,
  placeholder = "",
}: {
  title?: string;
  placeholder?: string;
}) => {
  const [value, setValue] = useState<number>();
  const [currency, setCurrency] = useState<"USD" | "IRT">("IRT");
  const updateForm = useContext(FormContext);

  useEffect(() => {
    updateForm((prev: any) => ({ ...prev, amount: value }));
  }, [value]);

  return (
    <div className="flex flex-col gap-y-3">
      {title && <label className="text-light-gray text-15px">{title}</label>}
      <div className="relative flex flex-grow items-center mb-2">
        <input
          className="bg-[#16161B] border-0 rounded-xl h-12 flex-grow price tracking-wider focus:ring-2 focus:ring-secondary-light"
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="absolute left-5 flex items-center gap-x-1 cursor-pointer hidden">
          <CurrencySelect {...{ currency, setCurrency, currencyDefinition }} />
        </div>
      </div>

      <AmountPreset
        preset={{
          USD: ["5", "10", "50", "100"],
          IRT: ["5000", "10000", "20000", "50000"],
        }}
        currency={currency}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};
