import { InstagramIcon } from "../../components/InstagramIcon";
import { TwitterIcon } from "../../components/TwitterIcon";
import { YoutubeIcon } from "../../components/YoutubeIcon";

export const Cover = ({
  hero,
  accent,
  goal,
  slug,
  background,
  color,
  noPadding,
}) => {
  return (
    <div
      className={
        "h-screen w-[40%] bg-cover bg-center fixed top-0 left-0 group overflow-hidden hidden lg:flex"
      }
    >
      <div
        className="w-full h-full absolute top-0 left-0 flex justify-center py-16 bg-no-repeat"
        style={{
          backgroundColor: accent,
          backgroundImage: `url(${background})`,
          backgroundSize: "100%",
          paddingTop: noPadding ? 0 : undefined,
        }}
      >
        <div className="flex flex-col justify-between gap-12">
          <div>
            {slug === "heisenbergofficial" && (
              <div className="absolute -top-[150px] -left-[150px] scale-100 group-hover:scale-105 transition-all ease-in-out duration-500">
                <div className="decorator-circles relative">
                  <div className="w-[650px] h-[650px] rounded-full border-[3px] border-white opacity-30 absolute top-0 left-0" />
                  <div className="w-[500px] h-[500px] rounded-full border-[3px] border-white opacity-30 absolute top-0 left-0" />
                  <div className="w-[350px] h-[350px] rounded-full border-[3px] border-white opacity-30 absolute top-0 left-0" />
                </div>
              </div>
            )}
            <div className="relative z-10">
              {hero && (
                <div className="mb-3 flex justify-center">
                  <img src={hero} />
                </div>
              )}

              {slug === "heisenbergofficial" && (
                <div
                  className="flex text-black justify-center gap-4"
                  style={{
                    color,
                  }}
                >
                  <TwitterIcon />
                  <InstagramIcon />
                  <YoutubeIcon />
                </div>
              )}
            </div>
          </div>
          {goal && (
            <div>
              <div className="bg-white text-black flex items-center gap-3 rounded-full py-4 pr-4 pl-9 h-20">
                <div className="relative flex justify-center items-center">
                  <span className="absolute text-sm">{/* ۲۳٪ */}</span>
                  <svg
                    className="svg-pi"
                    style={{
                      strokeDashoffset:
                        125.6 *
                          ((100 -
                            (goal?.progressAmount / goal?.maxAmount) * 100) /
                            100) +
                        "px",
                    }}
                  >
                    <circle className="svg-pi-track" />
                    <circle
                      className="svg-pi-indicator"
                      style={{
                        stroke: accent,
                      }}
                    />
                  </svg>
                </div>
                <div>
                  <h2>هدف حمایت:</h2>
                  <p className="font-bold">{goal?.title}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
