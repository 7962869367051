import { ChevronDownIcon } from "@heroicons/react/outline";
import Dropdown from "./Dropdown";
import { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";

const Item = ({ currency, setValue, currencyDefinition }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`${
            active ? "bg-indigo-600 text-white" : "text-white"
          } group flex rounded-lg items-center w-full px-4 py-3 text-sm`}
          onClick={() => setValue(currency)}
        >
          {currencyDefinition[currency] || currency}
        </button>
      )}
    </Menu.Item>
  );
};

export const CurrencySelect = ({
  currency,
  setCurrency,
  currencyDefinition,
}) => {
  const [value, setValue] = useState(currency);
  const preset = ["IRT", "USD"];
  useEffect(() => {
    if (!value) {
      return;
    }
    setCurrency(value);
  }, [value]);

  return (
    <div className="relative">
      <Dropdown value={currencyDefinition[value] || value}>
        <div>
          {preset?.map((currency) => (
            <Item currency={currency} {...{ currencyDefinition, setValue }} />
          ))}
        </div>
      </Dropdown>
    </div>
  );
};
