import logo from "../images/dono-logo-full.svg";

export const Footer = ({ slug }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between text-light-gray lg:items-end gap-3">
      <div className="flex gap-x-3 items-center order-2 lg:order-1">
        <img src={logo} />
      </div>
      <ul className="flex gap-x-4 list-unstyled add-dots order-1 lg:order-2">
        <li>گزارش مشکل</li>
        <li>
          <a href={`https://sub.dono.gg/${slug}`}>خرید سابسکرایب</a>
        </li>
      </ul>
    </div>
  );
};
