import { StreamerProfile } from "../../components/StreamerProfile";
import { Button } from "../../components/Button";

export const Header = ({ bio, name, avatar }) => {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-between">
        <StreamerProfile image={avatar} name={name} />
        <a href={`https://twitch.tv/${name}`} target="_blank">
          <Button type="secondary">بازگشت به استریم</Button>
        </a>
      </div>
      {bio && (
        <div className="description flex items-center gap-4">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.546875 7.7373V0.59375H7.14355V6.2334C7.14355 9.28678 6.79036 11.4857 6.08398 12.8301C5.10417 14.6758 3.57747 16.0658 1.50391 17L0 14.5732C1.23047 14.0719 2.14193 13.2858 2.73438 12.2148C3.34961 11.1211 3.69141 9.62858 3.75977 7.7373H0.546875ZM11.1426 7.7373V0.59375H17.7393V6.2334C17.7393 9.28678 17.3861 11.4857 16.6797 12.8301C15.6999 14.6758 14.1732 16.0658 12.0996 17L10.5957 14.5732C11.8262 14.0719 12.7376 13.2858 13.3301 12.2148C13.9453 11.1211 14.2871 9.62858 14.3555 7.7373H11.1426Z"
              fill="#4D4C5A"
            />
          </svg>
          <span className="text-[#76738B]">{bio}</span>
        </div>
      )}
    </div>
  );
};
