import particle from "../../images/particle.svg";
import { Input } from "../../components/Input";
import { AmountInput } from "../../components/AmountInput";
import { Button } from "../../components/Button";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { createContext, useEffect, useState } from "react";
import { TextArea } from "../../components/TextArea";
import axios from "axios";
import api from "../../config/api";
import { useSearchParams } from "react-router-dom";

export const FormContext = createContext<any>("");

export const DonateForm = ({
  slug,
  accent,
  color,
}: {
  slug: string;
  accent: string;
  color?: string;
}) => {
  const [searchParams] = useSearchParams();
  const paymentId = searchParams.get("paymentId");
  const guessedStatus = searchParams.get("guessedStatus");
  const [loading, setLoading] = useState(false);
  const [headingToBank, setHeadingToBank] = useState(false);
  const [formData, setFormData] = useState({
    donator: "",
    amount: "",
    phoneNumber: "",
    message: "",
  });

  const [formError, setFormError] = useState({
    donator: "",
    amount: "",
    phoneNumber: "",
    message: "",
  });

  const [apiError, setApiError] = useState("");

  const handleSendDonation = async () => {
    setFormError({
      donator: "",
      amount: "",
      phoneNumber: "",
      message: "",
    });
    const { donator, amount, phoneNumber, message } = formData;
    // if (!donator) {
    //   setFormError((prev) => ({
    //     ...prev,
    //     donator: "نام کاربری",
    //   }));
    // }
    if (!amount) {
      setFormError((prev) => ({ ...prev, amount: "مبلغ" }));
    }
    if (!phoneNumber) {
      setFormError((prev) => ({
        ...prev,
        mobile: "شماره موبایل",
      }));
    }
    // if (!message) {
    //   setFormError((prev) => ({
    //     ...prev,
    //     message: "متن دونیت",
    //   }));
    // }
    if (amount && phoneNumber) {
      try {
        setLoading(true);
        setHeadingToBank(true);
        setApiError("");

        const { data } = await api.post(`/donation/${slug}`, formData);
        if (!data) {
          return;
        }
        const { payment } = data;
        if (!payment) {
          setApiError(
            "امکان ورود به صفحه بانک وجود ندارد. لطفا مجددا تلاش کنید."
          );
          setHeadingToBank(false);
          return;
        }
        const { url } = payment;
        if (!url) {
          setApiError(
            "امکان ورود به صفحه بانک وجود ندارد. لطفا مجددا تلاش کنید."
          );
          setHeadingToBank(false);
          return;
        }
        window.location.href = url;
      } catch (error) {
        setHeadingToBank(false);
        if (!axios.isAxiosError(error)) {
          return setApiError("مشکلی پیش آمده است. لطفا دوباره تلاش کنید.");
        }
        const [errorMessage] = error?.response?.data?.message as string[];
        console.log(errorMessage);
        if (errorMessage.includes("phoneNumber")) {
          setApiError("شماره موبایل وارد شده صحیح نیست.");
        } else if (errorMessage.includes("amount")) {
          setApiError("مبلغ وارد شده صحیح نیست.");
        } else if (errorMessage.includes("donator")) {
          setApiError("نام کاربری وارد شده صحیح نیست.");
        } else if (errorMessage.includes("message")) {
          setApiError("متن دونیت وارد شده صحیح نیست.");
        } else if (errorMessage.includes("not setup yet")) {
          setApiError("استریمر قابلیت دریافت دونیت را فعال نکرده است.");
        } else if (errorMessage.includes("Rejected Response from Zarinpal")) {
          setApiError("امکان پرداخت از سمت زرین پال وجود ندارد.");
        } else {
          setApiError("مشکلی پیش آمده است. لطفا دوباره تلاش کنید.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const hasFormError = Object.values(formError).some((error) => error);

  return (
    <FormContext.Provider value={setFormData}>
      <div className="w-full">
        {hasFormError && (
          <div className="flex items-center gap-x-2 mb-4 border border-red-500 rounded-xl py-3 px-4">
            <span className="text-red-500">
              {Object.values(formError)
                .filter((error) => error)
                .join(", ")}{" "}
              را وارد کنید.
            </span>
          </div>
        )}

        {apiError && (
          <div className="flex items-center gap-x-2 mb-4 border border-red-500 rounded-xl py-3 px-4">
            <span className="text-red-500">{apiError}</span>
          </div>
        )}

        {headingToBank && (
          <div className="flex items-center gap-x-2 mb-4 border border-green-500 rounded-xl py-3 px-4">
            <span className="text-green-500">
              به صفحه بانک هدایت می شوید...
            </span>
          </div>
        )}

        {guessedStatus === "completed" && (
          <div className="flex items-center gap-x-2 mb-4 border border-green-500 rounded-xl py-3 px-4">
            <span className="text-green-500">پرداخت با موفقیت انجام شد</span>
            {paymentId && (
              <span className="text-green-500 text-xs">{paymentId}</span>
            )}
          </div>
        )}

        {guessedStatus === "canceled" && (
          <div className="flex items-center gap-x-2 mb-4 border border-amber-400 rounded-xl py-3 px-4">
            <span className="text-amber-400">پرداخت توسط کاربر لغو شد.</span>
            {paymentId && (
              <span className="text-amber-400 text-xs">{paymentId}</span>
            )}
          </div>
        )}

        {guessedStatus === "pending" && (
          <div className="flex items-center gap-x-2 mb-4 border border-white/60 rounded-xl py-3 px-4">
            <span className="text-white/80">پرداخت در حال پردازش است.</span>
            {paymentId && (
              <span className="text-white/80 text-xs">{paymentId}</span>
            )}
          </div>
        )}
        <div className="flex-grow bg-secondary rounded-xl p-8">
          <div className="flex items-center justify-between mb-4 hidden">
            <h1 className="font-semibold relative -mt-2 text-xl mb-4">
              حمایت مالی من
            </h1>
          </div>

          <div className="flex flex-col gap-y-6 flex-grow fa-num">
            <Input id="donator" placeholder="نام (اختیاری)" />
            <Input type="number" id="phoneNumber" placeholder="شماره موبایل" />
            <AmountInput placeholder="مبلغ حمایت" />
            <TextArea id="message" placeholder="متن دونیت (اختیاری)" />

            <div className="flex justify-between items-center text-light-gray">
              <span className="hidden">
                ممنون که هدف رو <span className="text-amber-300">۳٪</span> جلو
                می برید.
              </span>
              <div className="group w-full">
                <Button
                  type="primary"
                  disabled={loading}
                  block
                  onClick={handleSendDonation}
                  style={{
                    backgroundColor: accent,
                    color,
                  }}
                >
                  {!loading ? (
                    <>
                      پرداخت{" "}
                      <ArrowLeftIcon
                        className="w-4 h-4 text-dono-gray-600 mr-2 transition group-hover:-translate-x-1"
                        style={{
                          color,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      صبر کنید
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-dono-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormContext.Provider>
  );
};
