import { TwitterIcon } from "./TwitterIcon";
import { InstagramIcon } from "./InstagramIcon";
import partner from "../images/partner.svg";

export const StreamerProfile = ({ image, name }) => {
  return (
    <div>
      <div className="flex gap-x-6 items-center">
        <div className="relative">
          <img src={image} className="rounded-2xl w-14 h-14 fit-cover" />
          <div className="live-indicator bg-dark-blue rounded-full p-1.5 absolute -bottom-1.5 -right-1">
            <div
              className="bg-red-600 rounded-full animate-pulse"
              style={{ width: "9px", height: "9px" }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2.5">
          <h1 className="tracking-wide text-base font-medium font-inter opacity-90 flex items-center gap-x-2">
            {name} <img src={partner} />
          </h1>
          <div className="flex gap-x-3 text-light-gray opacity-50 hidden">
            <TwitterIcon />
            <InstagramIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
