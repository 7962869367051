import { useState } from "react";
import { StreamerProfile } from "../../components/StreamerProfile";
import avatar from "../../images/avatar.png";
import quote from "../../images/quote.svg";
import goal from "../../images/goal.webp";
import { Button } from "../../components/Button";
import { Footer } from "../../components/Footer";
import { Cover } from "./Cover";
import { Header } from "./Header";
import { DonateForm } from "./DonateForm";
import { Sidebar } from "./Sidebar";
import hashemHero from "../../images/hashem-hero.png";
import { useParams } from "react-router-dom";
import api from "../../config/api";
import { useEffect } from "react";
import hashemAvatar from "../../images/hashem-avatar.png";
import imanAvatar from "../../images/iman-avatar.jpeg";
import imanBackground from "../../images/iman-background.png";

const streamersTheme = {
  heisenbergofficial: {
    accent: "#FCD842",
    avatar: hashemAvatar,
    hero: hashemHero,
  },
  iman: {
    accent: "#1a286f",
    accentTextColor: "#fff",
    avatar: imanAvatar,
    hero: imanBackground,
    noPadding: true,
  },
};

export const StreamerPage = (props: { forcedSlug: string }) => {
  const [cover] = useState(
    "https://static-cdn.jtvnw.net/jtv_user_pictures/41f14612-5037-4c7e-b02a-92c3d56aad36-channel_offline_image-1920x1080.png"
  );
  const { streamer: slug } = useParams<{ streamer: string }>();
  const [streamerData, setStreamerData] = useState<any>();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/donation/${props?.forcedSlug}`);
      setStreamerData(data);
    })();
  }, [props?.forcedSlug]);

  const theme =
    streamersTheme[props?.forcedSlug as keyof typeof streamersTheme];

  const accent = theme?.accent;

  const color =
    ("accentTextColor" in theme && theme?.accentTextColor) || undefined;

  const noPadding = ("noPadding" in theme && theme?.noPadding) || false;

  return (
    <div className="flex justify-between min-h-screen">
      <div className="w-full lg:w-[60%]">
        <div className="px-6 lg:px-24 mx-auto py-6 lg:py-14 h-full">
          <div className="flex flex-col justify-between h-full gap-y-12 max-w-xl mx-auto">
            <Header
              bio={streamerData?.description}
              name={props?.forcedSlug}
              avatar={
                streamersTheme[props?.forcedSlug as keyof typeof streamersTheme]
                  ?.avatar
              }
            />
            <div className="flex gap-x-12">
              <DonateForm
                slug={props?.forcedSlug}
                accent={
                  streamersTheme[
                    props?.forcedSlug as keyof typeof streamersTheme
                  ]?.accent
                }
                color={color}
              />
            </div>
            <Footer slug={props?.forcedSlug} />
          </div>
        </div>
      </div>
      <Cover
        hero={
          streamersTheme[props?.forcedSlug as keyof typeof streamersTheme]?.hero
        }
        accent={accent}
        goal={streamerData?.donationGoal}
        slug={props?.forcedSlug}
        color={color}
        noPadding={noPadding}
      />
    </div>
  );
};
