import { useContext, useEffect, useState } from "react";
import { FormContext } from "../pages/StreamerPage/DonateForm";

export const Input = ({
  title,
  placeholder = "",
  id,
  type,
}: {
  title?: string;
  placeholder?: string;
  id: string;
  type?: string;
}) => {
  const [value, setValue] = useState<string>();
  const updateForm = useContext(FormContext);

  useEffect(() => {
    updateForm((prev: any) => ({ ...prev, [id]: value }));
  }, [value]);

  return (
    <div className="flex flex-col gap-y-3">
      {title && <label className="text-light-gray text-15px">{title}</label>}
      <input
        className="bg-[#16161B] border-0 rounded-xl h-12 px-4 focus:ring-2 focus:ring-secondary-light"
        placeholder={placeholder}
        type={type || "text"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
