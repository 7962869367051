import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./config/routes";
import "./App.scss";

function App() {
    return (
        <>
            <div>
                <Toaster />
            </div>
            <BrowserRouter>
                <Routes>
                    {routes.map(({ id, path, Element, subPath }) => (
                        <Route
                            key={id}
                            path={`${path}${subPath ? "/*" : ""}`}
                            element={Element}
                        />
                    ))}
                </Routes>
            </BrowserRouter>
        </>
);
}

export default App;
